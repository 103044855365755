

import React, { useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import { Hero } from './hero';
import { Settings } from './settings';

const HeroCtxDefault = {
  isWizard: false,
  body: 1,
  head: 1,
  spell: 1,
  eyes: 1,
  neck: 1,
  mouth: 1,
  tail: 1,
  wand: 1,
  rankIndex: 1,
}

function App() {
  const [hero, setHero] = useState(HeroCtxDefault);
  return (
    <Container>
      <Header>Traits viewer</Header>
        <Hero {...hero} />
        <Settings value={hero} set={setHero} />
    </Container>
  );
}

export default App;
