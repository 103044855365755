import { Message, Segment } from 'semantic-ui-react';
import { traitData } from './trait-data';

interface IImage {
    name: string;
    png: string;
}

const TRAITS = traitData as {
    [x: number]: {
        [k: number]: IImage
    }
}

export interface IHero {
    isWizard: boolean;
    body: number;
    head: number;
    spell: number;
    eyes: number;
    neck: number;
    mouth: number;
    tail: number;
    wand: number;
    rankIndex: number;
}

export const TraitImg = (props: IImage) => {
    const str = `data:image/svg+xml;base64,${props.png}`;
    const img = (
        <image
            x="4"
            y="4"
            width="512"
            height="512"
            imageRendering="pixelated"
            preserveAspectRatio="xMidYMid"
            xlinkHref={str}
        />
    );
    const n = (
      <>{props.name}<br/></>
    )
    return [n, img];  
}
  
export const Hero: React.FC<IHero> = (t) =>  {
    try {
    const shift = t.isWizard ? 0 : 9;
    const parts = [
      TraitImg(TRAITS[0 + shift][t.body]),
      t.isWizard
        ? TraitImg(TRAITS[4 + shift][t.neck])
        : '',
      t.isWizard
        ? TraitImg(TRAITS[1 + shift][t.head])
        : TraitImg(TRAITS[1 + shift][t.rankIndex]),
      t.isWizard
        ? TraitImg(TRAITS[2 + shift][t.spell])
        : '',
      TraitImg(TRAITS[3 + shift][t.eyes]),
      TraitImg(TRAITS[5 + shift][t.mouth]),
      t.isWizard
        ? ''
        : TraitImg(TRAITS[6 + shift][t.tail]),
      t.isWizard
        ? TraitImg(TRAITS[7 + shift][t.wand])
        : ''
    ];
  
    const imgs = parts.map(([, img]) => img);
    const names = parts.map(([name]) => name);
  
    return (
      <Segment.Group horizontal>
        <Segment>
          <svg
            id="wndNFT"
            width="520"
            height="520"
            version="1.1"
            viewBox="0 0 520 520"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {parts}
        </svg>
      </Segment>
      <Segment>
        {names}
      </Segment>
    </Segment.Group>
    );
    } catch (error) {
        console.error(error);
        return (
            <Segment>
                <Message error>
                    <Message.Header>{(error as any).message}</Message.Header>
                    <Message.Content>
                        {JSON.stringify(t, null, 2)}
                        <br/>
                        {(error as any).stack}
                        </Message.Content>
                </Message>
            </Segment>
        )
    }
}
