import { Form, Header, Segment } from "semantic-ui-react";
import { IHero } from "./hero";

interface ISettingsProps {
  value: IHero, set: React.Dispatch<React.SetStateAction<IHero>>
}

const IDMW: Partial<Record<keyof IHero, number>> = {
  body: 14,
  rankIndex: 4,
  eyes: 10,
  mouth: 14,
  tail: 7,
}

const IDMH: Partial<Record<keyof IHero, number>> = {
  body: 5,
  head: 9,
  spell: 11,
  eyes: 17,
  neck: 12,
  mouth: 12,
  wand: 12,
}

export const Settings: React.FC<ISettingsProps> = (props) => {

  const current = props.value;

  const update = <T extends keyof IHero>(key: T, valur: IHero[T]) => {
    props.set((prev) => {
      return {
        ...prev,
        [key]: valur,
      }
    })
  }

  function* elements() {
    yield (
      <Form.Radio
        key="asd"
        label="Hamster"
        checked={current.isWizard}
        onChange={(_, v) => update('isWizard', v.checked!)}
      />

    );

    yield (
      <Form.Radio
        key="asf2  s"
        label="Owl"
        checked={!current.isWizard}
        onChange={(_, v) => update('isWizard', !v.checked!)}
      />
    )

    const owl: (keyof IHero)[] = [
      'body',
      'rankIndex',
      'eyes',
      'mouth',
      'tail',
    ]

    const ham: (keyof IHero)[] = [
      'body',
      'head',
      'spell',
      'eyes',
      'neck',
      'mouth',
      'wand',
    ]

    const parts = current.isWizard ? ham : owl;


    for (const part of parts) {
      const maxMap = current.isWizard ? IDMH : IDMW;
      const max = maxMap[part] ? maxMap[part]! : 0
      console.log(max)
      yield (
        <div key={part + current.isWizard}>
          <Header>{part}</Header>
          <Form.Group inline>
            <Form.Input
              value={current[part]}
              input={{ size: 2 }}
            />
            <Form.Input
              type="range"
              key={part}
              min={0}
              max={max}
              value={current[part]}
              onChange={(_, { value }) => {
                console.log(part, value)
                return update(part, Number(value) - 1);
              }}
            />
          </Form.Group>
        </div>
      )
    }
  }

  return (
    <Segment>
      <Form>
        {[...elements()]}
      </Form>
    </Segment>
  );
  // return (
  //     <Input onChange={(e, v) => update('body', v)} />
  // )

}
